<template>
  <FormCard
    :title="vendor.trading_name ? vendor.trading_name : 'Novo fornecedor'"
    :status="vendor.status"
    @save="validate"
    @cancel="$router.push({ name: 'vendors' })"
  >
    <v-form
      ref="form"
      class="px-5 mt-5"
    >
      <v-tabs v-model="currentTab">
        <v-tabs-slider color="primary" />

        <v-tab
          v-for="item in tabsItems"
          :key="item.id"
          :disabled="isDisabled(item.title)"
        >
          {{ item.title }}
        </v-tab>

        <v-tabs-items v-model="currentTab">
          <v-tab-item
            v-for="item in tabsItems"
            :key="item.id"
            class="py-10"
          >
            <component
              :is="item.component"
              :vendor="vendor"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  components: {
    FormCard: () => import('@/components/FormCard'),
    MainData: () => import('./form-tabs/MainData'),
    Products: () => import('./form-tabs/Products'),
    Payments: () => import('./form-tabs/Payments'),
  },
  props: {
    vendor: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    currentTab: null,
    tabsItems: [
      { id: 1, title: 'Dados Principais', component: 'main-data' },
      { id: 2, title: 'Produtos', component: 'products' },
      { id: 3, title: 'Formas de pagamento', component: 'payments' },
    ],
    profile: null,
  }),
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
  methods: {
    isDisabled(title) {
      return (
        this.$route.matched[1].name === 'vendors-new' && title === 'Produtos'
      )
    },
    validate() {
      let isValid = this.$refs.form.validate()
      isValid ? this.$emit('save') : ''
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>